#contact-card {

  .MuiToolbar-root {
    justify-content: space-between;

    path{
      color: #fff;
    }
  }

  .MuiDialogTitle-root {
    h2{
      color: #fff !important;
    }
  }

  .jr-link{
    font-weight: bold;
  }

  .avatar-container {
    border-radius: 10%;
    overflow: hidden;
    width: 100%;
    aspect-ratio: 1;
    //height: auto;
    //max-height: 200px;

    //&:after {
    //  content: "";
    //  display: block;
    //  padding-bottom: 100%; /* The padding depends on the width, not on the height, so with a padding-bottom of 100% you will get a square */
    //}

    > img {
      object-position: center;
      object-fit: cover;
      width: 100%;
      height: 100%
    }
  }
}
