.extra-item-row{
  flex-direction: row;
  display: flex;
  width: 50%;
  background-color: aliceblue;
  border-radius: 13px;
  margin: 8px 13px;
  padding: 11px 12px;

  >div{
    margin: 0px 5px;
  }
}
