@font-face {
  font-family: 'Kurdish';
  src:  url('fonts/Rabar_041.woff2') format('woff2'),
  url('fonts/Rabar_041.woff') format('woff'),
  url('fonts/Rabar_041.ttf') format('ttf');
}


@media screen and (min-width: 1400px) {


}
@media screen and (min-width: 1600px) {


}
@media screen and (min-width: 1900px) {

  .products-list{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    .card {
      width: 50%;
    }
  }
  .products-grid{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    .card {
      width: 50%;
    }
  }

}

@media screen and (min-width: 2600px) {

  .products-list{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;

    .card {
      width: 33.33333%;
    }
  }
  .products-grid{
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;

    .card {
      width: 33.33333%;
    }
  }

}
.kurdish_font{
  input{

    font-family: Kurdish, serif !important;
  }
}
