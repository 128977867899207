#table-filter {
  width: 100%;
  height: 100px;
  display: inline;


  #range-slider {
    margin-top: 13%;

    .MuiSlider-root {
      color: #3a8589;
      height: 3px;
      padding: 13px 0;
    }
    .MuiSlider-thumb {
      height: 27px;
      width: 27px;
      background-color: #fff;
      border: 1px solid currentColor;
      margin-top: -12px;
      margin-left: -13px;
      box-shadow: #ebebeb 0 2px 2px;

      &:focus, &:hover, &:active {
        box-shadow: #ccc 0 2px 3px 1px;
      }
      & .bar {
        height: 9px;
        width: 1px;
        margin-left: 1px;
        margin-right: 1px;
      }
    }
    .MuiSlider-active {
    }
    .MuiSlider-valueLabel{
      left: -150%;
      top: 35px;
      width: 100px;

      >span{
        width: auto;
        height: 32px;
        display: flex;
        transform: rotate(0deg);
        align-items: center;
        border-radius: unset;
        justify-content: center;
        background-color: transparent;

        >span{
          color:black;
          transform: rotate(0deg);
          text-align: center;
        }
      }
    }
    .MuiSlider-track {
      height: 3px;
    }
    .MuiSlider-rail {
      color: #d8d8d8;
      opacity: 1;
      height: 3px
    }
  }
}
