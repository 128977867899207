#csv-exporter {
  width: 100%;

  .json-viewer {
    background-color: white;
    border-right: 1px black solid;
    width: 300px;
    height: 100vh;
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
  }

  .selected-file-container {
    .selected-file {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background-color: #0b2e13;

      label {
        color: white;
        margin: 0 20px;

      }

      button {
        border-radius: 2px;
        outline: none;
        border: none;
      }
    }

    .compression-table-container {
      width: 100%;
      display: flex;
      flex-direction: row;

      table {
        font-family: inherit;
        border-collapse: collapse;
        width: 100%;
      }

      th {
        background-color: #54c9ff;
      }

      .price-fields, .normal-fields {
        background-color: #dddddd !important;
        border-bottom: #5d5d5d 1px solid;
      }

      .text-fields {
        background-color: #ffffff !important;
      }

      td, th {
        font-family: inherit;
        border: 1px solid #dddddd;
        text-align: center;
        padding: 8px;


        &.normal-fields {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        //.MuiInput-root {
        //  width: 80%;
        //}

        span {
          font-family: inherit;
        }

        span:first-child {
          font-size: 16px;
        }

        span:nth-child(2) {
          font-size: 14px;
          font-weight: bold;
          text-decoration: underline;
        }

        span:nth-child(3) {
          font-size: 14px;
          font-weight: lighter;
          text-decoration: underline;
        }

        span:last-child {
          font-size: 14px;
          color: #1a237e;
          font-weight: lighter;
          text-decoration: underline;
        }
      }


      tr:nth-child(even) {
        background-color: #ccefff;
      }

    }

    .footer {
      position: fixed;
      bottom: 0;
      right: 0;
      padding: 0 50px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      z-index: 55;
      width: 100%;
      height: 100px;
      border-top: 2px black solid;
      background-color: white;

      button {
        padding: 15px 30px;
        border-radius: 2px;
        outline: none;
        border: none;
      }
    }
  }

  .export-button {

    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 300px;
      height: 300px;
      border-radius: 15px;
      outline: none;
      border: none;
    }

  }
}
