#product-view{


  .product_image{
    width: 100%;
    border-radius: 20px;
  }
  #comments-list{

    margin-top: 30px;

  }
}
