#sortable-tree-container{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .loader-view{
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: #00000045;
  }
  .sortable-tree {
    .rst__nodeContent {
      //background-color: black;
    }
  }
}



.title-block {

  display: flex;
  margin: 0;
  padding: 0;

  h3 {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .context-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0 5px;

  }
}

.context-menu-container {
  background-color: #d6d6d6;
  border-radius: 10px;
  width:130px;
  height: fit-content;
  position: absolute;
  .context-menu-buttons-list {
    margin: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .text-left{
      text-align: left;
    }
    .text-right{
      text-align: right;
    }

    .context-btn {
      width: 100%;
      margin:5px 0;


      :hover{
        background-color: lightgrey;
      }
      :last-child{
        margin-bottom: 0;
      }
    }
  }
}
